<style lang="less" scoped>
  .cell-item {
    position: relative;
  }
  .update-label-icon {
    position: absolute;
    right: 0;
    top: 25%;
    z-index: 1;
  }
  .picker-multiple-choose {
    display: flex;
    flex-direction: column;
    .title {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      height: 40px;
    }
    .chooses {
      overflow: auto;
      flex: 1;
      height: 10vh;
      max-height: 30vh;
    }
    .search {}
  }
</style>

<template>
  <div class="modify-apply-form">
    <div class="del-btn" v-if="needDel">
      <i class="icon-shanchu iconfont" @click="del(true)"></i>
    </div>
    <van-form>
      <div v-for="item in formParms.filter(v => !v.show || v.show(formData))" :key="item.key" class="cell-item">
        <template v-if="['input', 'textarea', 'password'].includes(item.type)">
          <van-field
            v-model="formData[item.key]"
            :name="item.label"
            :label="item.label"
            :readonly="item.readonly === true"
            :type="item.type"
            @change="onValueChange(item.key)"
            :disabled="typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled"
            :placeholder="item.placeholder || ('请输入' + item.label)"
            :rules="item.rules">
          </van-field>
        </template>
        <template v-if="item.type === 'select'">
          <van-field
            readonly
            :disabled="typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled"
            clickable
            :label="item.label"
            :value="getColumns(item).find((value) => value === formData[item.key]) || ''"
            :placeholder="item.placeholder || ('请选择' + item.label)"
            :rules="item.rules"
            @click="$set(picker, item.key, !item.readonly && !(typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled) && true)"
          />
          <van-popup v-model="picker[item.key]" round position="bottom">
            <van-picker
              :title="item.label"
              show-toolbar
              :columns="getColumns(item)"
              @cancel="$set(picker, item.key, false)"
              @confirm="(v, i) => (formData[item.key] = getColumns(item)[i], $set(picker, item.key, false), onValueChange(item.key))"
            >
              <van-search v-if="item.search" clearable slot="columns-bottom" v-model="search[item.key]" placeholder="请输入搜索关键词" />
            </van-picker>
          </van-popup>
        </template>
        <template v-if="item.type === 'multipleChoose'">
          <van-field
            readonly
            :disabled="typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled"
            clickable
            :label="item.label"
            :value="item.columns.filter(({value}) => Array.isArray(formData[item.key]) && formData[item.key].includes(value)).map(v => v.label).join(',')"
            :placeholder="item.placeholder || ('请选择' + item.label)"
            :rules="item.rules"
            @click="$set(picker, item.key, !item.readonly && !(typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled) && true)"
          />
          <van-popup v-model="picker[item.key]" round position="bottom" closeable>
            <div class="picker-multiple-choose">
              <div class="title">{{item.label}}</div>
              <div class="chooses">
                <van-checkbox-group v-model="formData[item.key]" @change="onValueChange(item.key)">
                  <template v-for="(option, optionIndex) in getColumns(item)">
                    <van-cell @click="$refs['checkboxes-' + item.key][optionIndex].toggle()" :title="option.label" clickable :key="option.value" v-if="!search[item.key] || search[item.key].indexOf(option.label) > -1 ">
                      <template slot="right-icon">
                        <van-checkbox :ref="'checkboxes-' + item.key" shape="square" :name="option.value" />
                      </template>
                    </van-cell>
                  </template>
                </van-checkbox-group>
              </div>
              <div class="search">
                <van-search v-if="item.search" clearable v-model="search[item.key]" placeholder="请输入搜索关键词" />
              </div>
            </div>
          </van-popup>
        </template>
        <van-field :label="item.label" v-if="item.type === 'cmp'">
          <component
            slot="input"
            :is="item.cmp"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            :placeholder="item.placeholder">
          </component>
        </van-field>
        <template v-if="['datePicker', 'datetimePicker'].includes(item.type)">
          <van-field
            readonly
            clickable
            :label="item.label"
            :value="item.format ? item.format(formData) : formatDate(item.type === 'datePicker' ? 'Y-M-D' : 'Y-M-D H:I:S', formData[item.key])"
            :placeholder="item.placeholder || ('请选择' + item.label)"
            @click="$set(picker, item.key, !item.readonly && !(typeof item.disabled === 'function' ? item.disabled(formData) : item.disabled) && true)"
          >
            <van-icon slot="button" name="clear" @click.stop.self="$set(formData, item.key, null)" v-if="formData[item.key]" />
          </van-field>
          <van-popup v-model="picker[item.key]" round position="bottom">
            <van-datetime-picker
              v-model="formData[item.key]"
              @cancel="picker[item.key] = false"
              @confirm="picker[item.key] = false"
              @change="onValueChange(item.key)"
              :min-date="datePickMini"
              :type="{datePicker: 'date', 'datetimePicker': 'datetime'}[item.type]"
              :title="item.placeholder"
            />
          </van-popup>
        </template>
        <update-label class="update-label-icon" :item="item" />
      </div>
    </van-form>

    <xun-ping @updateLogChange="updateLogChange" v-if="dataType === 'worker_certificate_pro_hire'" :update-log="formData.updateLog"/>
    <bian-geng @updateLogChange="updateLogChange" v-if="dataType === 'worker_certificate_doctor_pra'" :update-log="formData.updateLog"/>

    <file-upload
      v-if="dataFileParm"
      ref="fileUpload"
      @addFile="addFile"
      @delFile="delFile"
      :dataFileParm="dataFileParm">
    </file-upload>
    <!-- <file-upload-rel v-if="dataFileParm && dataFileParm.pDirPath.includes('worker_idcard')" ref="fileUploadRel" :dataFileParm="dataFileParm"></file-upload-rel> -->
    <div class="del-info" v-if="dataIsDel">
      <i class="icon-shanchu iconfont"></i>
      <div class="del-note">
        <div class="label-i" v-if="delStatus">{{delStatus}}</div>
        <p v-if="delStatus === '提'">删除申请已提交给管理员审批，审批通过即可生效。若要撤回删除申请，请点击取消删除后点击提交。</p>
        <p v-else>数据待删除，请点击提交将删除申请提交给管理员审批，审批通过即可生效。</p>
      </div>
      <fm-btn @click="del(false)">取消删除</fm-btn>
    </div>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

import FileUpload from './fileUpload'
// import FileUploadRel from './fileUploadRel'
import UpdateLabel from './updateLabel'
import XunPing from './xunping'
import BianGeng from './biangeng'

function idsArray (value) {
  const values = value && typeof value === 'string' ? value.split(',') : (value || [])
  if (values.every(v => !isNaN(v))) {
    return values.map(v => Number(v))
  } else {
    return values
  }
}

export default {
  name: 'ModifyApplyForm',
  components: {
    FileUpload, UpdateLabel, XunPing, BianGeng
  },
  props: {
    inline: {
      type: [Boolean, Number],
      default: false,
      validator: function (value) {
        return !(typeof value === 'number' && value < 1 && value > 4)
      }
    },
    needDel: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    delStatus: {
      type: String
    },
    isDel: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    sourceData: {
      type: Object,
      default: () => {
        return null
      }
    },
    dataFileParm: {
      type: Object,
      default: () => {
        return null
      }
    },
    draftData: {
      type: Object,
      default: () => {
        return null
      }
    },
    waitData: {
      type: Object,
      default: () => {
        return null
      }
    },
    waitKeys: {
      type: Array,
      default: () => {
        return []
      }
    },
    labelAlign: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    },
    formParms: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string' && typeof item.type === 'string' && typeof item.label === 'string'
          })
        }
        return pass
      }
    },
    onChange: {
      type: Function,
      default: undefined
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    dataType: {
      type: String,
      default: null
    }
  },
  watch: {
    draftData: {
      handler (value) {
        if (value) {
          Object.keys(value).forEach((key) => {
            const params = this.formParms.find(f => f.key === key)
            if (params && params.type === 'multipleChoose') {
              this.$set(this.formData, key, idsArray(value[key]))
              this.$set(this.hisData, key, idsArray(value[key]))
            } else if (params && params.type.indexOf('date') > -1) {
              this.$set(this.formData, key, value[key] ? (value[key] instanceof Date ? value[key] : new Date(value[key].replace(new RegExp('-', 'g'), '/'))) : new Date())
              this.$set(this.hisData, key, value[key] ? (value[key] instanceof Date ? value[key] : new Date(value[key].replace(new RegExp('-', 'g'), '/'))) : new Date())
            } else {
              this.$set(this.formData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
              this.$set(this.hisData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
            }
          })
        } else {
          this.formData = {}
          this.hisData = {}
        }
      },
      immediate: true,
      deep: true
    },
    formParms: {
      handler (value) {
        value.forEach(v => {
          this.$set(this.openSourceMap, v.key, false)
          this.$set(this.openWaitMap, v.key, false)
        })
      },
      immediate: true
    }
  },
  provide () {
    return {
      form: this
    }
  },
  data () {
    return {
      datePickMini: new Date('1900/01/01'),
      popover: {},
      picker: {},
      search: {},
      key: 0,
      dataIsDel: this.isDel,
      formData: {},
      verifierPass: false,
      loading: false,
      openSourceMap: {},
      openWaitMap: {},
      hisData: {}
    }
  },
  methods: {
    async addFile (fileData) {
      await this.$emit('addFile', fileData)
    },
    async delFile (fileData) {
      await this.$emit('delFile', fileData)
    },
    getColumns (item) {
      let data = []
      if (item.columns) {
        data = item.columns
      } else if (item.getColumns) {
        data = item.getColumns(this.formData)
      }
      data = data.filter(v => !this.search[item.key] || v.label.indexOf(this.search[item.key]) > -1).map(v => v.label)
      return data
    },
    reloadFile () {
      if (this.$refs.fileUpload) {
        this.$refs.fileUpload.loadFile()
      }
      // if (this.$refs.fileUploadRel) {
      //   this.$refs.fileUploadRel.loadFile()
      // }
    },
    formatDate (type, value) {
      return value ? this.$datetime.format(value, type) : ''
    },
    del (isDel) {
      this.dataIsDel = isDel
      this.$emit('del', isDel)
    },
    hisWait (key) {
      return this.waitKeys.includes(key)
    },
    checkValueWitchSource (formParm) {
      let format = formParm.format
      let compare = formParm.compare
      if (compare) {
        return compare(this.sourceData) === compare(this.formData)
      } else if (format) {
        return format(this.sourceData) === format(this.formData)
      } else {
        return this.sourceData[formParm.key] === this.formData[formParm.key]
      }
    },
    verifier () {
      let noPass = this.formParms.filter(v => !v.show || v.show(this.formData)).find(item => {
        if (item.check) {
          return this.$verifier.check(this.formData[item.key], item.check.required === true, (item.check.rules || [])) !== ''
        }
      })
      this.verifierPass = noPass === undefined
    },
    updateLogChange (data) {
      data.forEach((v) => {
        Object.keys(v).forEach((key) => {
          if (v[key] instanceof Date) {
            v[key] = this.formatDate('Y-M-D H:I:S', v[key])
          }
        })
      })
      this.$set(this.formData, 'updateLog', data && data.length > 0 ? JSON.stringify(data) : null)
      this.verifier()
      let data2 = dcopy(this.formData)
      Object.keys(data2).forEach((v) => {
        if (data2[v] instanceof Date) {
          data2[v] = this.formatDate('Y-M-D H:I:S', data2[v])
        }
      })
      this.$emit('formChange', {data: data2, pass: this.verifierPass})
    },
    onValueChange (key) {
      if (typeof this.onChange === 'function') {
        const change = this.onChange(key, this.formData[key], this.formData)
        change && Object.keys(change).forEach(key => {
          this.$set(this.formData, key, change[key])
        })
      }
      this.verifier()
      let isChange = false
      this.formParms.forEach((item) => {
        let key = item.key
        if (!isChange && this.formData[key] !== this.hisData[key]) {
          isChange = true
          if (Array.isArray(this.formData[key]) && Array.isArray(this.hisData[key])) {
            isChange = this.formData[key].join(',') !== this.hisData[key].join(',')
          } else if (this.formData[key] instanceof Date && this.hisData[key]) {
            isChange = this.formData[key].getTime() !== new Date(this.hisData[key]).getTime()
          }
        }
      })
      if (isChange) {
        this.hisData = dcopy(this.formData)
        let data = dcopy(this.formData)
        Object.keys(data).forEach((v) => {
          if (data[v] instanceof Date) {
            data[v] = this.formatDate('Y-M-D H:I:S', data[v])
          }
        })
        this.$emit('formChange', {data: data, pass: this.verifierPass})
      }
    }
  }
}
</script>

<style lang="less">
.modify-apply-form {
  .del-info {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
      font-size: 50px;
      color: #999;
      margin-bottom: 30px;
    }
  }
  .label-i {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F4628F;
    border-radius: 9px;
    color: #fff;
    padding: 0 9px;
    font-size: 12px;
    font-weight: 100;
  }
  .del-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    z-index: 2;
    background: #ED4D75;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    border-bottom-left-radius: 15px;
  }
  width: calc(100% - 21px);
  height: calc(100% - 30px);
  background: #fff;
  border-radius: 15px;
  position: relative;
  padding: 20px 16px 10px 5px;
  overflow-y: auto;
  box-shadow: 0px 4px 30px rgba(232, 120, 145, 0.15);
  .fm-form-content {
    padding: 0 !important;
  }
  .del-note {
    background: #fff;
    .label-i {
      margin-right: 15px;
    }
    display: flex;
    align-items: center;
    margin: 0 20px 20px;
    p {
      flex: 1;
      color: #777;
      line-height: 2;
    }
  }
}
</style>