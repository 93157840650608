<template>
  <page-base :show-btn="true" @save="save" @submit="submit">
    <div class="form-page">
      <data-form
        :need-del="formData.id && (!formData || !formData.isDel)"
        :source-data="sourceInfo"
        :draft-data="formData"
        :wait-data="modifyApplyInfo"
        :wait-keys="waitKeys"
        :form-parms="useFormParms"
        @formChange="formChange"
        @del="del"
        :data-type="dataType"
        @addFile="addFile"
        @delFile="delFile"
        ref="dataForm"
        :isDel="formData && formData.isDel"
        :delStatus="delStatus"
        :onChange="onChange"
        :is-absolut="true"
        :dataFileParm="useDataFileParm">
      </data-form>
    </div>
  </page-base>
</template>

<script>
import PageBase from './pageBase'
import DataForm from './dataForm'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    PageBase,
    DataForm
  },
  props: {
    sourceInfo: {
      type: Object
    },

    dataFileParm: {
      type: Object
    },
    editData: {
      type: Object
    },
    edit: {
      type: Object
    },
    modifyApplyInfo: {
      type: Object
    },
    modifyApply: {
      type: Object
    },
    waitKeys: {
      type: Array
    },
    onChange: {
      type: Function
    },
    formParms: {
      type: Array
    },
    dataType: {
      type: String
    },
    workerId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      formData: {},
      show: false,
      isPass: this.editData && true,
      changed: false,
      isDel: this.editData && this.editData.isDel
    }
  },
  watch: {
    editData: {
      handler (value) {
        if (value) {
          Object.keys(value).forEach((key) => {
            this.$set(this.formData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
          })
        } else {
          this.formData = {}
          this.hisData = {}
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    useDataFileParm () {
      let data = null
      if (this.formData && this.dataFileParm) {
        data = Object.assign({}, this.dataFileParm, {
          fileIds: this.formData.fileIds ? this.formData.fileIds.split(',') : []
        })
      }
      return data
    },
    delStatus () {
      let data = null
      if (this.formData && this.formData.isDel) {
        if (this.edit && this.edit.id && this.edit.isDel) {
          data = '存'
          if (this.modifyApply && this.modifyApply.type === 'del') {
            data = '提'
          }
        }
      }
      return data
    },
    useFormParms () {
      return this.formParms ? this.formParms.map(v => {
        if (['select', 'multipleChoose'].includes(v.type)) {
          if (v.selectDatas) {
            v.columns = v.selectDatas
          } else {
            v.getColumns = v.getSelectDatas
          }
          // v.columns = (v.selectDatas ? v.selectDatas : v.getSelectDatas(this.formPage.draftData)).map(c => ({value: c.key, label: c.label}))
        }
        if (v.fmDisabled || v.getFmDisabled) {
          v.disabled = v.fmDisabled ? v.fmDisabled : v.getFmDisabled(this.formData)
        }
        return v
      }) : []
    }
  },
  methods: {
    async addFile (fileData) {
      let fileIds = this.formData.fileIds ? this.formData.fileIds.split(',') : []
      fileIds.push(fileData.id)
      let parm = Object.assign({}, this.formData, {
        fileIds: fileIds.join(','),
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      await this.formChange({
        pass: true,
        data: parm
      })
      await this.save(true)
    },
    async delFile (fileData) {
      let fileIds = this.formData.fileIds ? this.formData.fileIds.split(',') : []
      fileIds = fileIds.filter(v => Number(v) !== fileData.id)
      let parm = Object.assign({}, this.formData, {
        fileIds: fileIds.join(','),
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      await this.formChange({
        pass: true,
        data: parm
      })
      await this.save(true)
    },
    del (isDel) {
      this.formData.isDel = isDel
      this.isPass = true
      this.changed = true
    },
    formChange (data) {
      this.isPass = data.pass
      this.formData = data.data
      this.changed = true
    },
    async save (isFile) {
      if (this.changed && !this.isPass) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        return
      }
      await this.$emit('save', this.formData, this.changed, false, isFile)
    },
    async submit () {
      await this.$emit('submit', this.formData, this.changed, true)
    }
  }
}
</script>

<style lang="less" scope>
.form-page {
  width: calc(100% - 50px);
  height: calc(100% - 20px);
  padding: 18px 25px 0;
}
</style>