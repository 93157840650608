<style scoped lang="less">
  .title {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: space-between;
    .label {
      color: #000000;
      font-size: 16px;
    }
    .add-btn {
      padding: 3px 12px;
      background-color: #C4C4C4;
      color: #FFFFFF;
      font-size: 12px;
    }
  }
</style>

<template>
  <div class="xunping">
    <div class="title">
      <span class="label">变更记录</span>
      <span class="add-btn" @click="status.addAction = true">+新增</span>
    </div>

    <div :key="item.id + '-' + i" v-for="(item, i) in updateLogList">
      <van-cell
        v-if="item.type === 'change'"
        @click="edit(item)"
        title="变更注册"
        :value="item.changeContent"
        :label="formatDate('Y-M-D', item.changeDate)" is-link
      />
      <van-cell
        v-if="item.type === 'goon'"
        @click="edit(item)"
        title="延续注册"
        :label="formatDate('Y-M-D', item.registDate) + '-' + formatDate('Y-M-D', item.registEndDate)" is-link
      />
      <van-cell
        v-if="item.type === 'first'"
        @click="edit(item)"
        title="首次注册"
        :label="formatDate('Y-M-D', item.registDate) + '-' + formatDate('Y-M-D', item.registEndDate)" is-link
      />
    </div>

    <van-action-sheet close-on-click-action cancel-text="关闭" v-model="status.addAction" :actions="[{value: 'first', name: '新增首次注册', txt: '首次注册'}, {value: 'goon', name: '新增延续注册', txt: '延续注册'}, {value: 'change', name: '新增变更注册', txt: '变更注册'}]" @select="addActionSelect" />
    
    <van-popup
      v-model="status.form"
      closeable
      :close-on-click-overlay="false"
      close-on-popstate
      safe-area-inset-bottom
      round
      position="bottom"
    >
      <div style="text-align: center;font-weight: 500;font-size: 16px;line-height: 40px;height: 40px;">{{(data.id ? '修改' : '新增') + logType.txt + '记录'}}</div>
      <van-form @submit="onSubmit">
        <template v-if="logType.value === 'change'">
          <van-field
            v-model="data.changeContent"
            name="变更事项"
            label="变更事项"
            placeholder="请输入变更事项"
            :rules="[{ required: true, message: '请输入变更事项' }]"
          />

          <van-field
            readonly
            clickable
            label="注册日期"
            :value="formatDate('Y-M-D', data.changeDate)"
            placeholder="请选择注册日期"
            @click="picker.changeDate = true"
          >
            <van-icon slot="button" name="clear" @click.stop.self="$set(data, 'changeDate', null)" v-if="data.changeDate" />
          </van-field>
          <van-popup v-model="picker.changeDate" round position="bottom">
            <van-datetime-picker
              v-model="data.changeDate"
              @cancel="picker.changeDate = false"
              @confirm="picker.changeDate = false"
              :min-date="datePickMini"
              type="date"
              title="请选择注册日期"
            />
          </van-popup>
        </template>

        <template v-else>
          <van-field
            readonly
            clickable
            label="注册日期"
            :value="formatDate('Y-M-D', data.registDate)"
            placeholder="请选择注册日期"
            @click="picker.registDate = true"
          >
            <van-icon slot="button" name="clear" @click.stop.self="$set(data, 'registDate', null)" v-if="data.registDate" />
          </van-field>
          <van-popup v-model="picker.registDate" round position="bottom">
            <van-datetime-picker
              v-model="data.registDate"
              @cancel="picker.registDate = false"
              @confirm="picker.registDate = false"
              :min-date="datePickMini"
              type="date"
              title="请选择注册日期"
            />
          </van-popup>
  
          <van-field
            readonly
            clickable
            label="注册有效期至"
            :value="formatDate('Y-M-D', data.registEndDate)"
            placeholder="请选择注册有效期至"
            @click="picker.registEndDate = true"
          >
            <van-icon slot="button" name="clear" @click.stop.self="$set(data, 'registEndDate', null)" v-if="data.registEndDate" />
          </van-field>
          <van-popup v-model="picker.registEndDate" round position="bottom">
            <van-datetime-picker
              v-model="data.registEndDate"
              @cancel="picker.registEndDate = false"
              @confirm="picker.registEndDate = false"
              :min-date="datePickMini"
              type="date"
              title="请选择注册有效期至"
            />
          </van-popup>
        </template>

        <div style="margin: 16px;">
          <van-button :loading="loading.submit" color="#F4628F" round block type="info" native-type="submit">确定</van-button>
        </div>
      </van-form>
      <div style="margin: 16px;" v-if="data.id">
        <van-button :loading="loading.submit" @click="del" round block type="default">删除</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
function toDate (data) {
  return data ? (data instanceof Date ? data : new Date(data.replace(new RegExp('-', 'g'), '/'))) : new Date()
}

export default {
  props: {
    updateLog: { type: String, default: null }
  },
  data () {
    return {
      datePickMini: new Date('1900/01/01'),
      logType: {},
      data: {},
      status: {
        form: false,
        addAction: false,
      },
      loading: {
        submit: false
      },
      picker: {
        registDate: false,
        registEndDate: false,
        changeDate: false,
      }
    }
  },
  computed: {
    updateLogList () {
      return this.updateLog ? JSON.parse(this.updateLog) : []
    }
  },
  methods: {
    addActionSelect (logType) {
      this.logType = logType
      if (logType.value === 'change') {
        this.data = {
          id: null,
          type: logType.value,
          changeDate: new Date(),
          changeContent: null
        }
      } else {
        this.data = {
          id: null,
          type: logType.value,
          registDate: new Date(),
          registEndDate: new Date()
        }
      }
      this.status.form = true
    },
    async del () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该记录吗?'})
      if (result) {
        let data = this.updateLogList.filter((v) => this.data.id !== v.id)
        this.$emit('updateLogChange', data)
        this.status.form = false
      }
    },
    edit (data) {
      if (data.type === 'change') {
        this.data = {
          id: data.id,
          type: data.type,
          changeDate: toDate(data.changeDate),
          changeContent: data.changeContent
        }
      } else {
        this.data = {
          id: data.id,
          type: data.type,
          registDate: toDate(data.registDate),
          registEndDate: toDate(data.registEndDate)
        }
      }
      this.status.form = true
    },
    async onSubmit () {
      try {
        if (this.data.registDate && this.data.registEndDate && this.data.registDate >= this.data.registEndDate) {
          throw new Error('注册结束日期需要大于注册起始日期。')
        }

        const index = this.data.id ? this.updateLogList.findIndex(v => v.id === this.data.id) : -1
        let data = this.updateLogList.map(v => v)
        if (index > -1) {
          data[index] = this.data
        } else {
          this.data.id = new Date().getTime()
          data.push(this.data)
        }

        this.$emit('updateLogChange', data)

        this.status.form = false
      } catch (error) {
        this.$notify({ type: 'danger', message: error.message })
      }
    },
    formatDate (type, value) {
      return value ? this.$datetime.format(toDate(value), type) : ''
    }
  }
}
</script>
