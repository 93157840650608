<style lang="less" scoped>
.update-label {}
.icon {
  line-height: 18px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
  background: #F4628F;
  color: #fff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
}

.content {
  padding: 10px;
}
.value {
  color: #323233;
  font-size: 14px;
}
.label {
  color: #969799;
  font-size: 12px;
}
</style>


<template>
  <div class="update-label">
    <template v-if="sourceData && !checkValueWitchSource(item)">
      <van-popover placement="left" v-model="poper1" trigger="click">
        <template slot="reference">
          <div class="icon">改</div>
        </template>
        <div class="content">
          <template v-if="!item.formatCmp">
            <div class="label">该字段当前输入了与原值不同的结果。</div>
            <div class="value">{{(item.format ? item.format(sourceData) : sourceData[item.key]) ? ('原值: ' + (item.format ? item.format(sourceData) : sourceData[item.key])) : '原值为空'}}</div>
          </template>
          <template v-else>
            <div class="label">该字段当前输入了与原值不同的结果, 原值:</div>
            <component
              class="value"
              :is="item.formatCmp"
              :value="sourceData[item.key]">
            </component>
          </template>
        </div>
      </van-popover>
    </template>
    <template v-if="hisWait(item.key)">
      <van-popover placement="left" v-model="poper2" trigger="click">
        <template slot="reference">
          <div class="icon">提</div>
        </template>
        <div class="content">
          <template v-if="!item.formatCmp">
            <div class="label">该字段当前提交了修改申请。</div>
            <div class="value">{{(item.format ? item.format(waitData) : waitData[item.key]) ? ('申请值为: ' + (item.format ? item.format(waitData) : waitData[item.key])) : '申请值为空'}}</div>
          </template>
          <template v-else>
            <div class="label">该字段当前提交了修改申请, 申请值为:</div>
            <component
              class="value"
              :is="item.formatCmp"
              :value="waitData[item.key]">
            </component>
          </template>
        </div>
      </van-popover>
    </template>
  </div>
</template>

<script>
export default {
  inject: ['form'],
  props: {
    item: { type: Object, default: null }
  },
  data () {
    return {
      poper1: false,
      poper2: false
    }
  },
  computed: {
    sourceData () {
      return this.form.sourceData
    },
    waitData () {
      return this.form.waitData
    },
    checkValueWitchSource () {
      return this.form.checkValueWitchSource
    },
    hisWait () {
      return this.form.hisWait
    }
  }
}
</script>
