<style scoped lang="less">
  .title {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    justify-content: space-between;
    .label {
      color: #000000;
      font-size: 16px;
    }
    .add-btn {
      padding: 3px 12px;
      background-color: #C4C4C4;
      color: #FFFFFF;
      font-size: 12px;
    }
  }
</style>

<template>
  <div class="xunping">
    <div class="title">
      <span class="label">续聘记录</span>
      <span class="add-btn" @click="add">新增续聘</span>
    </div>
    <van-cell
      v-for="(item, i) in updateLogList"
      :key="item.lId + '-' + i"
      class="van-cell"
      @click="edit(item)"
      :title="item.hirePro"
      :label="formatDate('Y-M-D', item.hireStartDate) + ' - ' + formatDate('Y-M-D', item.hireEndDate)" is-link
    />
    <van-popup
      v-model="status.form"
      closeable
      :close-on-click-overlay="false"
      close-on-popstate
      safe-area-inset-bottom
      round
      position="bottom"
    >
      <div style="text-align: center;font-weight: 500;font-size: 16px;line-height: 40px;height: 40px;">{{(data.lId ? '修改' : '新增') + '续聘记录'}}</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="data.hirePro"
          name="专业"
          label="专业"
          placeholder="请输入专业"
          :rules="[{ required: true, message: '请输入专业' }]"
        />
        <van-field
          v-model="data.hireWorker"
          name="聘任职务"
          label="聘任职务"
          placeholder="请输入聘任职务"
          :rules="[{ required: true, message: '请输入聘任职务' }]"
        />
        <van-field
            readonly
            clickable
            label="岗位类型"
            :value="(jobTitleTypes.find(({key}) => key === data.xlmc) || {label: ''}).label"
            placeholder="请选择岗位类型"
            @click="picker.xlmc = true"
        />
        <van-popup v-model="picker.xlmc" round position="bottom">
          <van-picker
            title="岗位类型"
            show-toolbar
            :columns="jobTitleTypes.map(v => v.label)"
            @cancel="picker.xlmc = false"
            @confirm="(v, i) => (data.xlmc = jobTitleTypes[i].key, picker.xlmc = false)"
          >
          </van-picker>
        </van-popup>

        <van-field
            readonly
            clickable
            label="岗位等级"
            :value="(mTypeList.find(({key}) => key === data.mType) || {label: ''}).label"
            placeholder="请选择岗位等级"
            @click="picker.mType = true"
        />
        <van-popup v-model="picker.mType" round position="bottom">
          <van-picker
            title="岗位等级"
            show-toolbar
            :columns="mTypeList.map(v => v.label)"
            @cancel="picker.mType = false"
            @confirm="(v, i) => (data.mType = mTypeList[i].key, picker.mType = false)"
          >
          </van-picker>
        </van-popup>

        <van-field
          readonly
          clickable
          label="聘期起始日期"
          :value="formatDate('Y-M-D', data.hireStartDate)"
          placeholder="请选择聘期起始日期"
          @click="picker.hireStartDate = true"
        >
          <van-icon slot="button" name="clear" @click.stop.self="$set(data, 'hireStartDate', null)" v-if="data.hireStartDate" />
        </van-field>
        <van-popup v-model="picker.hireStartDate" round position="bottom">
          <van-datetime-picker
            v-model="data.hireStartDate"
            @cancel="picker.hireStartDate = false"
            @confirm="picker.hireStartDate = false"
            :min-date="datePickMini"
            type="date"
            title="请选择聘期起始日期"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          label="聘期终止日期"
          :value="formatDate('Y-M-D', data.hireEndDate)"
          placeholder="请选择聘期终止日期"
          @click="picker.hireEndDate = true"
        >
          <van-icon slot="button" name="clear" @click.stop.self="$set(data, 'hireEndDate', null)" v-if="data.hireEndDate" />
        </van-field>
        <van-popup v-model="picker.hireEndDate" round position="bottom">
          <van-datetime-picker
            v-model="data.hireEndDate"
            @cancel="picker.hireEndDate = false"
            @confirm="picker.hireEndDate = false"
            :min-date="datePickMini"
            type="date"
            title="请选择聘期终止日期"
          />
        </van-popup>
        <div style="margin: 16px;">
          <van-button :loading="loading.submit" color="#F4628F" round block type="info" native-type="submit">确定</van-button>
        </div>
      </van-form>
      <div style="margin: 16px;" v-if="data.lId !== null && data.lId !== undefined">
        <van-button :loading="loading.submit" @click="del" round block type="default">删除</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>

function toDate (data) {
  return data ? (data instanceof Date ? data : new Date(data.replace(new RegExp('-', 'g'), '/'))) : new Date()
}

export default {
  props: {
    updateLog: { type: String, default: null }
  },
  data () {
    return {
      datePickMini: new Date('1900/01/01'),
      data: {
        lId: null,
        hirePro: null,
        hireWorker: null,
        xlmc: null,
        mType: null,
        hireStartDate: new Date(),
        hireEndDate: new Date(),
      },
      status: {
        form: false
      },
      loading: {
        submit: false
      },
      picker: {
        xlmc: false,
        mType: false,
        hireStartDate: false,
        hireEndDate: false,
      }
    }
  },
  computed: {
    updateLogList () {
      let data = this.updateLog ? JSON.parse(this.updateLog) : []
      data.forEach((v,index) => v.lId = index + 1)
      return data
    },
    jobTitleTypes () {
      return this.$store.getters.selectDatas['job_title_type']
    },
    jobTitleList () {
      return this.$store.getters.jobTitleList
    },
    mTypeList () {
      let data = []
      if (this.data && this.data.xlmc) {
        data = this.jobTitleList.filter(v => v.data.type === this.data.xlmc).map((v) => {
          return {
            key: v.data.title,
            label: v.data.title
          }
        })
      }
      return data
    },
    formParmsAdd () {
      return [
        {
          type: 'datePicker',
          label: '聘期终止日期',
          key: 'hireEndDate'
        }
      ]
    }
  },
  methods: {
    async del () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该续聘记录吗?'})
      if (result) {
        let data = this.updateLogList.filter((v) => this.data.lId !== v.lId)
        data.forEach((v2, index2) => v2.lId = index2 + 1)
        this.$emit('updateLogChange', data)
        this.status.form = false
      }
    },
    edit (data) {
      this.data = data
      Object.keys(this.data).forEach(key => {
        if (['hireStartDate', 'hireEndDate'].includes(key)) {
          this.data[key] = toDate(data[key])
        } else {
          this.data[key] = data[key]
        }
      })
      this.status.form = true
    },
    async onSubmit () {
      console.log('onSubmit')
      try {
        if (this.data.hireStartDate && this.data.hireEndDate && this.data.hireStartDate >= this.data.hireEndDate) {
          throw new Error('聘期终止日期需要大于聘期起始日期。')
        }
        let datas = this.updateLogList.map(v => v)
        const index = (this.data.lId !== null && this.data.lId !== undefined) ? datas.findIndex(v => v.lId === this.data.lId) : -1
        if (index > -1) {
          datas[index] = this.data
        } else {
          this.data.lId = this.updateLogList.length + 1
          datas.push(this.data)
        }
        this.$emit('updateLogChange', datas)

        this.status.form = false
      } catch (error) {
        this.$notify({ type: 'danger', message: error.message })
      }
    },
    add () {
      this.data = {
        lId: null,
        hirePro: null,
        hireWorker: null,
        xlmc: null,
        mType: null,
        hireStartDate: new Date(),
        hireEndDate: new Date(),
      }
      this.status.form = true
    },
    formatDate (type, value) {
      return value ? this.$datetime.format(toDate(value), type) : ''
    }
  },
  mounted () {
    this.$store.dispatch('loadJobTitleList')
  }
}
</script>
