<template>
  <div class="form-page">
    <div class="header">
      <img class="bg1" src="/static/images/mobile/menu/head-bg.png" />
    </div>
    <div class="c-slot">
      <slot></slot>
    </div>
    <div class="btm-btn" v-if="showBtn">
      <div class="btn-item b-a" @click="save">保存</div>
      <div class="btn-item b-h" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>


<style lang="less" scope>
.form-page {
  background: #fff;
  position: relative;
  & > .header {
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    height: 0;
    .bg1 {
      width: 100%;
    }
  }
  .btm-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    background: #fff;
    display: flex;
    padding: 0 30px;
    justify-content: space-around;
    align-items: center;
    .btn-item {
      border-radius: 4px;
      font-size: 16px;
      width: 96px;
      height: 32px;
      display: flex;
      color: #fff;
      justify-content: center;
      align-items: center;
    }
    .b-a, .b-h {
      background: linear-gradient(#ED4D75, #FF839A);
    }
  }
  .c-slot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 60px;
  }
}
</style>
